import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { breakpoints, colors, fonts } from './../../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
const StyledBox = styled.div`
  background: ${colors.white};
  p {
    margin-bottom: 0;
  }
  .box-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .box-content {
    padding: 1rem;

    a {
      color: ${colors.secondaryAlt};
    }
    a:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    .box-content-title {
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    .box-content-clientAuthor {
      margin-bottom: 0.5rem;
      color: ${colors.primaryGray};
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin: 0;
  }
  img {
    margin-bottom: 0;
  }
`;

const ServiceRelatedBox = props => {
  const {
    type,
    image,
    altText,
    title,
    clientAuthor,
    excerpt,
    to,
    ...remProps
  } = props;
  return type === 'case' ? (
    <StyledBox {...remProps}>
      <div className="box-image">
        <img src={image} alt={altText} />
      </div>

      <div className="box-content">
        <p className="box-content-clientAuthor">{clientAuthor}</p>
        <p className="box-content-title">{title}</p>
        {ReactHtmlParser(excerpt)}
        <Link to={to}>Read More</Link>
      </div>
    </StyledBox>
  ) : (
    <StyledBox {...remProps}>
      <div className="box-image">
        <img src={image} alt={altText} />
      </div>

      <div className="box-content">
        <p className="box-content-title">{title}</p>
        <p className="box-content-clientAuthor">{clientAuthor}</p>
        {ReactHtmlParser(excerpt)}
        <Link to={to}>Read More</Link>
      </div>
    </StyledBox>
  );
};

export default ServiceRelatedBox;
