import React, { Fragment, Component } from 'react';
import { Link, graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';
import { camelizeKeys } from 'humps';
import _ from 'lodash';

import { colors, breakpoints, fonts } from '../style-utilities/variables';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';

//import components
import NavBar from '../components/NavBar';
import ServiceRelated from '../components/Services/ServiceRelated';
import PostHero from '../components/PostHero';
import Hero from '../components/Hero';
import ContactBanner from '../components/ContactBanner';
import Related3Grid from '../components/Related/Related3Grid';
import GlobalOurClients from '../components/GlobalOurClients';
import DefaultSlider from '../components/TabSliders/DefaultSlider/DefaultSlider';
import SEO from '../components/SEO';

const StyledServicesPage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  .page-container {
    max-width: 1500px;
    margin: 0 auto;
  }
  .left-copy {
    strong {
      color: black;
    }
    .intro-copy {
      width: 100%;
      margin-bottom: 0 !important;
      p {
        font-size: 26px;
      }
    }
    p {
      font-size: 22px;
    }
  }
  .right-image {
    text-align: center;
  }
  .feature-cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: ${breakpoints.tabletMax}) {
      padding-top: 6rem;
      width: 65%;
    }
    .feature-cards {
      @media (min-width: ${breakpoints.tablet}) {
        width: 47%;
      }
      .feature-headline {
        font-size: 26px;
      }
      .feature-card {
        margin-bottom: 2rem;
        margin-left: 0;
        margin-right: 1rem;
        .feature-list {
          list-style: none;
          color: #757575;
          margin-left: 2.45rem;
        }
        .feature-list-title {
          font-weight: bold;
          font-size: 16px;
          color: ${colors.black};
          margin-bottom: 15px;
        }
        .feature-content {
          color: #757575;
        }
      }
    }
  }
  .under-toggle {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .center-section {
    text-align: center;
    padding: 0 0 3rem;
    h2 {
      margin-bottom: 30px;
    }
    .img-column-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      margin-bottom: 20px;
      .column {
        max-width: 164px;
        a:hover {
          text-decoration-color: black;
        }
        img {
          width: 100px;
        }
      }
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    .left-copy {
      width: 65%;
    }
    .under-toggle {
      padding-top: 0;
    }
    .center-section {
      padding: 6rem 0 10rem;
      h2 {
        margin-bottom: 48px;
      }
      .img-column-container .column {
        max-width: 204px;
      }
    }
  }
`;

class ServicesPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    /*
    Preview Mode -------------------------------------
    */
    if (params.get('preview')) {
      let id = params.get('id');
      let wpnonce = params.get('_wpnonce');
      let BASE_URL = `https://api.dev.ansira.io/wp-json/wp/v2`;

      fetch(
        `${BASE_URL}/services_offered/${id}/revisions/?_wpnonce=${wpnonce}`,
        {
          mode: 'cors',
          credentials: 'include'
        }
      )
        .then(response => {
          return response.json();
        })
        .then(myJson => {
          if (myJson) {
            this.setState({
              isPreviewMode: true,
              previewPayload: myJson[0]
            });
          }
        });
    }
    /*
    -------------------------------------------------
    */
  }

  render() {
    let post = this.props.data.wordpressWpSolutionsPost,
      solPost = this.props.data.wordpressWpOurSolutions,
      blogPosts = this.props.data.allWordpressPost.edges,
      newsArticles = this.props.data.allWordpressWpNews.edges,
      caseStudies = this.props.data.allWordpressWpCaseStudy.edges,
      whitePapers = this.props.data.allWordpressWpWhitePaper.edges,
      arrayBlogs = [],
      arrayNews = [],
      arrayCases = [],
      arrayPapers = [];

    if (this.state.isPreviewMode) {
      post.acf = this.state.previewPayload.acf;
      post.title = this.state.previewPayload.title.rendered;
      post.content = this.state.previewPayload.content.rendered;
    }

    //hold mixed item content
    const arrayMixed = [];
    const arrayAll = [];

    // Look for Blog Posts with matching solution tag as current solution L2
    blogPosts.forEach(item => {
      item.node.our_solutions.forEach(svc => {
        if (
          item.node.slug !== 'do-not-delete' &&
          svc === solPost.wordpress_id
        ) {
          arrayBlogs.push(item.node);
        }
      });
    });
    // If no related solution get latest blog post
    if (arrayBlogs.length === 0) {
      arrayBlogs.push(blogPosts[0].node);
    }

    // Look for News Articles with matching solution tag as current solution L2
    newsArticles.forEach(item => {
      item.node.our_solutions.forEach(svc => {
        if (
          item.node.slug !== 'do-not-delete' &&
          svc === solPost.wordpress_id
        ) {
          arrayNews.push(item.node);
        }
      });
    });
    // If no related solution get latest News Post
    if (arrayNews.length === 0) {
      arrayNews.push(newsArticles[0].node);
    }

    // Look for Case Studies with matching solution tag as current solution L2
    caseStudies.forEach(item => {
      item.node.our_solutions.forEach(svc => {
        if (
          item.node.slug !== 'do-not-delete' &&
          svc === solPost.wordpress_id
        ) {
          arrayCases.push(item.node);
        }
      });
    });
    // If no Case Study with related solution add most recent case study to related article section
    if (arrayCases.length === 0) {
      arrayCases.push(caseStudies[0].node);
    }

    // Look for White Papers with matching solution tag as current solution L2
    whitePapers.forEach(item => {
      item.node.our_solutions.forEach(svc => {
        if (
          item.node.slug !== 'do-not-delete' &&
          svc === solPost.wordpress_id
        ) {
          arrayPapers.push(item.node);
        }
      });
    });
    // If no White Paper with a related solution add most recent white paper to related article section
    if (arrayPapers.length === 0) {
      arrayPapers.push(caseStudies[0].node);
    }

    //filter out dummy data (already being done above)
    // const filterArrays = arr => {
    //   return arr.filter(el => el[0].slug !== 'do-not-delete');
    // };

    // const filteredBlogs = filterArrays(arrayBlogs);

    //Push related content into a single array and sort by date
    //=========================================================
    if (arrayBlogs) {
      arrayBlogs.forEach(function(o) {
        arrayAll.push(o);
      });
    }
    if (arrayCases) {
      arrayCases.forEach(function(o) {
        arrayAll.push(o);
      });
    }
    if (arrayPapers) {
      arrayPapers.forEach(function(o) {
        arrayAll.push(o);
      });
    }

    //Only add news if images have been added for news articles
    //============================================
    // if (arrayNews) {
    //   arrayNews.forEach(function(o) {
    //     arrayAll.push(o);
    //   });
    // }

    arrayAll.sort(function(a, b) {
      return new Date(b.date) - new Date(a.date);
    });

    arrayMixed.push(arrayAll[0]); //skim the first 3 related articles to display
    arrayMixed.push(arrayAll[1]);
    arrayMixed.push(arrayAll[2]);

    const content = {
      background_image: "",
      hero_first_line: post.acf.hero_first_line ? post.acf.hero_first_line : "",
      hero_sub_header: post.acf.hero_sub_header,
      hero_sub_paragraph: post.acf.hero_sub_paragraph,
      hero_sub_button_text: post.acf.hero_sub_button_text,
      hero_sub_button_url: post.acf.hero_sub_button_url,
      hero_sub_headline: post.acf.hero_sub_headline,
      hero_sub_paragraph: post.acf.hero_sub_paragraph
    }

    return (
      <StyledServicesPage className="animate">
        <SEO
          isBlogPost={false}
          postData={post}
          postImage={DefaultSharingImage}
        />
        <Hero
          headline={post.acf.hero_headline}
          sub={post.acf.hero_sub_headline}
          fontSize="48px"
        />
        <div className="page-container">
          <div className="left-copy">
            <p className="intro-copy">{ReactHtmlParser(post.acf.intro_copy)}</p>
            <PostHero content={content} />
            <p>{ReactHtmlParser(post.acf.overview_copy)}</p>
          </div>
        </div>

        <DefaultSlider
          data={post.acf.create_slider}
          type={post.acf.slider_type}
          bgcolor="black"
        />
        <div className="under-toggle page-container">
          <GlobalOurClients
            headline={post.acf.client_logo_headline}
            logos={post.acf.client_logos}
            quote={ReactHtmlParser(post.acf.quote)}
          />
          <div className="center-section">
            <h2>{post.acf.section_headline}</h2>
            <div className="img-column-container">
              {post.acf.column_content.map((col, i) => {
                return (
                  <div className="column">
                    {col.column_link ? (
                      <Link to={col.column_link}>
                        <img src={col.column_image.source_url} />
                        <h4>{col.column_headline}</h4>
                        {col.column_copy ? (
                          <p className="copy">{col.column_copy}</p>
                        ) : (
                          <></>
                        )}
                      </Link>
                    ) : (
                      <div>
                        <img src={col.column_image.source_url} />
                        <h4>{col.column_headline}</h4>
                        {col.column_copy ? (
                          <p className="copy">{col.column_copy}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <Related3Grid header="Read These Next" items={arrayMixed} />
        </div>

        <ContactBanner
          headline={post.acf.contact_headline}
          cta={post.acf.contact_cta}
          url={post.acf.contact_url}
        />
      </StyledServicesPage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpSolutionsPost(slug: { eq: $slug }) {
      title
      type
      content
      slug
      acf {
        seo_title
        seo_canonical
        seo_description
        hero_headline
        hero_sub_headline
        hero_first_line
        hero_second_line
        hero_cta
        hero_url
        hero_sub_header
        hero_sub_paragraph
        hero_sub_button_text
        hero_sub_button_url
        video_link
        intro_copy
        overview_copy
        slider_type
        create_slider {
          slider_headline
          default_slider {
            slider_label
            slider_copy
            slider_cta_text
            slider_cta_url
          }
        }
        client_logo_headline
        client_logos {
          source_url
        }
        quote
        section_headline
        column_content {
          column_image {
            source_url
          }
          column_link
          column_headline
          column_copy
        }
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
    wordpressWpOurSolutions(slug: { eq: $slug }) {
      wordpress_id
      slug
    }
    allWordpressWpOurSolutions {
      edges {
        node {
          wordpress_id
          slug
          name
        }
      }
    }
    allWordpressWpWhitePaper {
      edges {
        node {
          wordpress_id
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          our_solutions
          topic
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressWpNews {
      edges {
        node {
          wordpress_id
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          our_solutions
          topic
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          wordpress_id
          content
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          author {
            name
            slug
          }
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
          topic
          our_solutions
        }
      }
    }
    allWordpressWpCaseStudy {
      edges {
        node {
          wordpress_id
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          topic
          our_solutions
          better_featured_image {
            alt_text
            source_url
          }
          acf {
            client
            client_logo {
              source_url
            }
            quote_copy
            quote_author
            excerpt
          }
        }
      }
    }
  }
`;

export default ServicesPageTemplate;
