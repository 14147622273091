import React, { Component } from 'react';
import ButtonRed from './ButtonRed';
import ReactHTMLParser from 'react-html-parser';

const PostHero = (props) => {
    return (
        <>
            <div className="hero-sub mb-4 pb-4">
                {/* Adding sub header and copy and button to hero for additional content when desired */}
                {props.content.hero_sub_header ? (
                <React.Fragment>
                    <h2 className="hero-sub-title">{ReactHTMLParser(props.content.hero_sub_header)}</h2>
                    <div className="post-hero-content mb-4 intro-copy">
                        {ReactHTMLParser(props.content.hero_sub_paragraph)}
                    </div>
                    {props.content.hero_sub_button_text ? (
                        <ButtonRed
                            textLabel={props.content.hero_sub_button_text}
                            pageLink={props.content.hero_sub_button_url}
                        />
                    ) : (
                    ''
                    )}
                </React.Fragment>
                ) : (
                ''
                )}
            </div>
            {props.content.hero_cta ? (
                <div className="cta">
                <ButtonRed
                    textLabel={props.content.hero_cta}
                    pageLink={props.content.hero_url}
                />
                </div>
            ) : (
                ''
            )}
        </>
    )
}

export default PostHero;