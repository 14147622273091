import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fonts } from './../../style-utilities/variables';

import ServiceRelatedBox from './ServiceRelatedBox';

const StyledCuratedBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  max-width: 30rem;
  @media (min-width: ${breakpoints.tablet}) {
    max-width: 40rem;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
  @media (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);

    max-width: 100rem;
    grid-gap: 1.25rem;
  }
  #box1 {
    @media (min-width: ${breakpoints.tablet}) {
      grid-column: 1;
      grid-row: 1;
    }
    @media (min-width: ${breakpoints.desktop}) {
      grid-column: 1;
      grid-row: 1;
    }
  }
  #box2 {
    @media (min-width: ${breakpoints.tablet}) {
      grid-column: 1 / span 2;
      grid-row: 2;
    }
    @media (min-width: ${breakpoints.desktop}) {
      grid-column: 2 / span 2;
      grid-row: 1 / span 2;
    }
  }
  #box3 {
    @media (min-width: ${breakpoints.tablet}) {
      grid-column: 2;
    }
    @media (min-width: ${breakpoints.desktop}) {
      grid-column: 1;
      grid-row: 2;
    }
  }
`;

const ServiceRelated = props => {
  const { box1, box2, box3 } = props;
  return (
    <StyledCuratedBoxes>
      <ServiceRelatedBox
        id="box1"
        type="blog"
        image={
          box1.better_featured_image
            ? box1.better_featured_image.source_url
            : null
        }
        altText={
          box1.better_featured_image
            ? box1.better_featured_image.alt_text
            : null
        }
        title={box1.title}
        clientAuthor={box1.author.name}
        excerpt={box1.acf.excerpt}
        to={'/insights/blog/' + box1.slug}
      />
      <ServiceRelatedBox
        id="box2"
        type="case"
        image={
          box2.better_featured_image
            ? box2.better_featured_image.source_url
            : null
        }
        altText={
          box2.better_featured_image
            ? box2.better_featured_image.alt_text
            : null
        }
        title={box2.title}
        clientAuthor={box2.acf.client}
        excerpt={box2.acf.excerpt}
        to={'/results/case-studies/' + box2.slug}
      />
      <ServiceRelatedBox
        id="box3"
        type="blog"
        image={
          box3.better_featured_image
            ? box3.better_featured_image.source_url
            : null
        }
        altText={
          box3.better_featured_image
            ? box3.better_featured_image.alt_text
            : null
        }
        title={box3.title}
        clientAuthor={box3.author.name}
        excerpt={box3.acf.excerpt}
        to={'/insights/blog/' + box3.slug}
      />
    </StyledCuratedBoxes>
  );
};

export default ServiceRelated;
